//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'WebLink',
	data() {
		return {
			url: '',
		}
	}
}
